<template>
  <div class="app-container">
    <CrudTable ref="table" entity="ReferalMunicipality" :columns="columns" />
  </div>
</template>

<script>
import crudIndexMixin from '@/mixins/crud-index';
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  mixins: [crudIndexMixin],
  data() {
    return {
      columns: [
        { field: 'id', width: 100, label: 'common.id' },
        { field: 'name', label: 'common.reason' }

        // PLOP: vueIndexColumns
      ]
    };
  }
};
</script>

<style scoped></style>
